import { render, staticRenderFns } from "./Checkout.vue?vue&type=template&id=5d34f52d&scoped=true&"
import script from "./Checkout.vue?vue&type=script&lang=js&"
export * from "./Checkout.vue?vue&type=script&lang=js&"
import style0 from "./Checkout.vue?vue&type=style&index=0&id=5d34f52d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d34f52d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
