<template>
    <div id="checkout-page">
        <ExplorePageTitles title="Review your order"/>
        <div id="empty-cart" v-if="shoppingCart.length == 0">
            <h1>There's nothing in your cart!</h1>
            <v-btn
                id="back-to-shop-btn"
                color="#e89f4c"
                @click="$router.go('/shop')"
                >
                Back To Shop
            </v-btn>
        </div>
        <div id="checkout-item-container" v-for="item in shoppingCart" :key="item.id">
            <CheckoutItem :itemId="item.id" />
            <hr>
        </div>
        <div 
            v-if="shoppingCart.length != 0" 
            id="total-price"
            >
            Total: ${{totalPrice}} CAD
        </div>
        <v-btn
            id="checkout-final"
            color="success"
            v-if="shoppingCart.length != 0"
            :disabled="shoppingCart.length == 0"
            @click="sendCheckoutData"
            >
            Checkout
        </v-btn>
    </div>
</template>

<script>
import axios from 'axios'
import CheckoutItem from '../components/CheckoutItem.vue'
import ExplorePageTitles from '../components/ExplorePageTitles.vue';

    export default {
        name: "Checkout",
        components: {
            CheckoutItem,
            ExplorePageTitles,
        },
        computed: {
            shoppingCart() {
                return this.$store.state.shoppingCart;
            },
            totalPrice() {
                let cart = this.$store.state.shoppingCart;
                let cartPrice = 0;
                for (let i=0;i<cart.length;i++) {
                    cartPrice += Number(cart[i].price * cart[i].quantity);
                }

                return cartPrice;
            }
        },
        mounted() {
            let cart = this.$store.state.shoppingCart;
            if (cart.length == 0) {
                this.$router.push('/shop');
            }
        },
        methods: {
            sendCheckoutData() {
                let cartPayload = this.shoppingCart
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/checkout_session',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "cart": cartPayload,
                    }
                }).then((response) => {
                    this.$store.commit("emptyTheCart");
                    window.location.href = response.data;
                }).catch((error) => {
                    console.log(error);
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    #checkout-page {
        width: 100vw;
        padding: 10px 20px;
        background-color: white;
        min-height: calc(100vh - 140px);
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        box-shadow: 0 5px 15px  rgb(131, 131, 131);
        font-family: "Helvetica";
        display: grid;

        #empty-cart {
            width: 100%;
            display: grid;
            justify-items: center;
            text-align: center;

            h1 {
                color: rgb(231, 14, 14);
            }
        }

        #back-to-shop-btn {
            padding: 45px 20px;
            font-size: 1.4em;
            color: white;
        }

        #checkout-item-container {
            width: 100%;
            display: grid;
            justify-items: center;
        }

        #total-price {
            font-size: 2em;
            font-weight: bold;
            justify-self: end;
            margin: 20px 0;
        }

        #checkout-final {
            padding: 25px 15px;
            width: 90%;
            max-width: 400px;
            justify-self: center;
        }
    }

@media screen and (min-width: 600px) {
    #checkout-page {
        margin-bottom: 150px; //account for absolute footer height
        padding: 10px 15px;
        align-items: center;

        #empty-cart {
            height: 100%;

            h1 {
                color: rgb(231, 14, 14);
            }

            #back-to-shop-btn {
                width: 60%;
            }
        }

        #checkout-final {
            width: 90%;
            max-width: 500px;
        }
    }
}

@media screen and (min-width: 1000px) {
    #checkout-page {
        max-width: 1350px;
        min-height: calc(100vh - 230px);
        margin-top: 80px; //account for fixed nav-bar height
        display: grid;
        padding: 10px 30px;

        #total-price {
            margin-right: 100px;
        }

        #checkout-final {
            padding: 35px 15px;
            width: 90%;
            max-width: 700px;
            font-size: 2em;
        }
    }
}
</style>