<template>
    <div id="checkout-item">
        <div id="checkout-img-container">
            <img :src="product.imageUrl" alt="Item image">
        </div>
        <h1 id="checkout-item-name">{{product.name}}</h1>
        <span v-if="product.chosenSize != null" id="checkout-item-size">Size: {{product.chosenSize}}</span>
        <span id="checkout-item-quantity">
            <p>Quantity:</p>
            <div class="checkout-item-arrow" @click="removeQuantity(product.id)"> - </div>
            <span id="checkout-quantity">{{product.quantity}}</span>
            <div class="checkout-item-arrow" @click="addQuantity(product.id)"> + </div>
        </span>
        <span id="checkout-item-price">${{product.price}} CAD</span>
        <v-btn 
            id="checkout-item-remove"
            color="error"
            @click="removeItem(product.id)"
            >
            Remove
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "CheckoutItem",
        props: ["itemId"],
        computed: {
            product() {
                let cart = this.$store.state.shoppingCart;
                return cart.find(x => x.id == this.itemId);
            },
        },
        methods: {
            removeQuantity(id) {
                this.$store.commit("removeCartQuantity", id);
            },
            addQuantity(id) {
                this.$store.commit("addCartQuantity", id);
            },
            removeItem(id) {
                this.$store.commit("removeItemFromCart", id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    #checkout-item {
        width: 95%;
        font-family: "Helvetica";
        
        #checkout-img-container {
            display: grid;
            justify-items: center;
            img {
                width: 100%;
                margin: 10px 0;
                max-width: 400px;
            }
        }

        #checkout-item-name {
            color: #d57f1e;
        }

        #checkout-item-size {
            font-size: 1.4em;
            font-weight: bold;
        }

        .checkout-item-arrow {
            cursor: pointer;
            border: 3px solid #d57f1e;
            background-color: #d5801e10;
            width: fit-content;
            padding: 5px 20px;
            border-radius: 10px;
            position: relative;
            bottom: 7px;
        }

        #checkout-item-quantity {
            display: grid;
            grid-auto-flow: column;
            margin-top: 10px;
        }

        #checkout-item-price {
            font-weight: bold;
        }

        #checkout-item-remove {
            display: block;
            padding: 0 10px;
            margin: 20px 0;
        }
    }

@media screen and (min-width: 600px) {
    #checkout-item {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: repeat(5, 20%);
        align-items: end;

        #checkout-img-container {
            display: grid;
            justify-items: center;
            align-items: center;
            width: 100%;
            max-width: 400px;
            grid-row: 1 / 6;
            
            img {
                width: 95%;
            }
        }

        #checkout-item-name {
            font-size: 1.2em;
            grid-column: 2;
            grid-row: 1;
        }

        #checkout-item-size {
            font-size: 1.7em;
            grid-column: 2;
            grid-row: 2;
        }

        #checkout-item-quantity {
            width: 100%;
            margin-top: 15px;
            grid-column: 2;
            grid-row: 3;
        }

        #checkout-item-price {
            font-size: 1.2em;
            margin-left: 0;
            justify-self: start;
            grid-column: 2;
            grid-row: 4;
        }

        #checkout-item-remove {
            width: 50%;
            grid-row: 5;
            grid-column: 2;
        }
    }
}

@media screen and (min-width: 1000px) {
    #checkout-item {

        #checkout-img-container {
            justify-self: center;
        }

        #checkout-item-name {
            font-size: 2em;
        }

        #checkout-item-quantity {
            font-size: 1.4em;
        }

        #checkout-item-price {
            font-size: 1.5em;
        }
    }
}
</style>